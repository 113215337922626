.page {
    position: relative;
    display: flex;
    flex-shrink: 0;
    outline: 1px solid #ccc;
    width: 600px;
    height: 600px;
    margin: 12px 0;
    background-color: bisque;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 2%);
}
